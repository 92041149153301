export const environment = {
  production: true,

  ic_quicko_logo: 'https://cdn.quicko.com/assets/images/logo.svg',
  ic_quicko_word_logo: 'https://dev1-accounts.quicko.com/assets/icons/ic_quicko_word_logo.svg',
  ic_google_logo: 'https://dev1-accounts.quicko.com/assets/icons/ic_google_logo.svg',

  // product logos
  ic_quicko_logo_circle: 'https://dev1-accounts.quicko.com/assets/icons/ic_quicko_logo_circle.svg',
  ic_meet_logo_circle: 'https://dev1-accounts.quicko.com/assets/icons/ic_meet_logo_circle.svg',
  ic_dark_quicko_logo_circle: 'https://dev1-accounts.quicko.com/assets/icons/ic_dark_quicko_logo_circle.svg',

  // social icons
  ic_twitter: 'https://dev1-accounts.quicko.com/assets/icons/ic_twitter.svg',
  ic_linkedin: 'https://dev1-accounts.quicko.com/assets/icons/ic_linkedin.svg',
  ic_instagram: 'https://dev1-accounts.quicko.com/assets/icons/ic_instagram.svg',

  // endpoints
  auth_services_endpoint: 'https://gmk0rc54l6.execute-api.ap-south-1.amazonaws.com/dev1',
  authentication_host: 'https://dev1-accounts.quicko.com/',
  reference_data_endpoint: 'https://d2meog3si9nlw1.cloudfront.net',

  //urls
  myaccount_quicko_host: 'https://dev1-myaccount.quicko.com',
  income_tax_url: "https://dev1-it.quicko.com"

  // common icons

  // common images
};
